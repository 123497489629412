import { SvgIconProps } from "../types";

const divStyle = {

  color: 'blue',

  backgroundColor: 'lightgray',

  'margin-top': '2rem'

};


export const SvgIcon = ({ src, width, height }: SvgIconProps) => (
  <img src={`/img/svg/${src}`} alt={src} width={width} height={height} />
);

export const EyedropsIcon = ({ src, width, height }: SvgIconProps) => (
  <img src={`/img/eyedrops/${src}`} alt={src} width={width} height={height} style={divStyle} />
);
